import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import en_translation from "../../localization/en/translation.json";
import {
  AppButton,
  AppIcon,
  AppToggleSwitch,
  AppAlert,
  AppModal,
} from "../../theme";
import { add, trash, editPen, floppyDisk } from "../../theme/icons";
import List from "../../components/editableGrid/list/list";
import axios from "axios";
import { API_URL } from "../../store/api";
import "./quickLinks.scss";
import { GridApi } from "ag-grid-community";
import {
  validateGUID,
  validateProfessionalUrl,
  validateFormInputForSpecialChar,validateInternalDomain
} from "./../../utils/helper-utility";
import Loader from "../../components/loader/loader";
import eyLogoWithCE from "../../assets/images/Logo.svg";;
/* eslint-disable */
type Props = {
  projectId: string;
  isAdmin: boolean;
  isInternalUser: boolean;
};

type Link = {
  id: string;
  title: string;
  url: string;
  visibility: boolean;
};

type Error = {
  id: string;
  title?: string;
  url?: string;
};

const QuickLinks: React.FC<Props> = ({
  projectId,
  isAdmin,
  isInternalUser,
}) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [isNewLink, setIsNewLink] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<string | null>(null); // Track which row is being edited
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [titleErrorList, setTitleErrorList] = useState<Error[]>([]);
  const [urlErrorList, setUrlErrorList] = useState<Error[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentParams, setCurrentParams] = useState<any>(null);
  const [isValidatingUrl, setValidatingUrl] = useState<boolean>(false);
  const [urlCategory, setUrlCategory] = useState<string>("");
  const setTitleError = (error: Error | null) => {
    if (error) {
      setTitleErrorList((prevErrors) => [...prevErrors, error]);
    } else {
      setTitleErrorList([]);
    }
  };
  const setUrlError = (error: Error | null) => {
    if (error) {
      setUrlErrorList((prevErrors) => [...prevErrors, error]);
    } else {
      setUrlErrorList([]);
    }
  };

  // Quick Links API
  const getProjectQuickLinks = useCallback(
    async (projectId: string) => {
      const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
      try {
        setIsLoading(true);
        const response = await axios.get(url);
        setLinks(response.data);
        setIsNewLink(false);
        setIsEditing(null);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching project quick links:", error);
      }
    },
    [projectId]
  );

  const addProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
    try {
      await axios.post(url, link);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error adding project quick link:", error);
    }
  };

  const updateProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${link.id}`;
    try {
      await axios.put(url, link);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error updating project quick link:", error);
    }
  };

  const deleteProjectQuickLink = async (projectId: string, linkId: string) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${linkId}`;
    try {
      await axios.delete(url);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error deleting project quick link:", error);
    }
  };

  useEffect(() => {
    getProjectQuickLinks(projectId);
  }, [getProjectQuickLinks, projectId]);

  const handleCellValueChanged = (params: any) => {
    if (validateGUID(params.data.id)) {
      updateProjectQuickLink(projectId, params.data);
      setLinks((prevLinks) =>
        prevLinks.map((link) =>
          link.id === params.data.id ? params.data : link
        )
      );
    } else {
      addProjectQuickLink(projectId, params.data);
      setIsNewLink(false);
    }
  };

  useEffect(() => {
    if (isNewLink && links.length > 0 && !validateGUID(links[0]?.id)) {
      listRef.current?.api.setFocusedCell(0, "title");
      listRef.current?.api.startEditingCell({
        rowIndex: 0,
        colKey: "title",
      });
    }
  }, [isNewLink, links]);

  const handleDelete = async (data: any) => {
    if (validateGUID(data.id) && links.filter((x) => x.id === data.id).length) {
      await deleteProjectQuickLink(projectId, data.id);
    }
    setLinks(links.filter((x) => x.id !== data.id));
    removeRowFromGrid(data);
    setUrlErrorList(urlErrorList.filter((x) => x.id !== data.id));
    setTitleErrorList(titleErrorList.filter((x) => x.id !== data.id));
  };

  const handleAddLink = () => {
    setIsNewLink(true);
    setIsEditing(links.length.toString());
    const newLink = {
      id: links.length.toString(),
      title: "",
      url: "",
      visibility: false,
    };
    listRef.current?.api.applyTransaction({ add: [newLink] });
    listRef.current?.api.refreshCells({ force: true });
    listRef.current?.api.setFocusedCell(links.length, "title");
    setLinks((prevLinks) => [...prevLinks, newLink]);
  };

  const onGridReady = (gridRef: any) => {
    listRef.current = gridRef;
    if (isNewLink && links.length <= 1) {
      listRef.current?.api.setFocusedCell(links.length - 1, "title");
      listRef.current?.api.startEditingCell({
        rowIndex: links.length - 1,
        colKey: "title",
      });
    }
  };

  const removeRowFromGrid = (data: any) => {
    listRef.current!.api.applyTransaction({ remove: [data] });
    setLinks(links.filter((x) => x.id !== data.id));
    if (isEditing === data.id) {
      setIsEditing(null);
      setIsNewLink(false);
    }
  };

  const isEmptyRow = (params: any) => {
    if (!params.data.title && !params.data.url) {
      removeRowFromGrid(params.data);
      setIsEditing(null);
      setIsNewLink(false);
      return true;
    }
    return false;
  };
  const resetError = (params : any ) => {
    setUrlErrorList(urlErrorList.filter((x) => x.id !== params.data.id));
    setTitleErrorList(titleErrorList.filter((x) => x.id !== params.data.id));
  }
  const isValidData = (params: any) => {
    if (
      params.data.title == "" || params.data.title == null ||
      validateFormInputForSpecialChar(params.data.title)
    ) {
      setTitleError({
        id: params.data.id,
        title:
          params.data.title == "" || params.data.title == null
            ? en_translation.quick_link_title_required
            : en_translation.quick_link_title_error,
      });
      return false;
    } else {
      setTitleErrorList(titleErrorList.filter((x) => x.id !== params.data.id));
    }
    return true;
  };

  const onRowEditingStopping = (params: any) => {
    params.api.stopEditing(false);
    resetError(params);
    if (isEmptyRow(params)) {
      return;
    }
    if (params.data.url == "" || params.data.url == null) {
      setUrlError({
        id: params.data.id,
        url: en_translation.quick_link_url_required,
      });
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: "url",
      });
      return;
    }else if (validateProfessionalUrl(params.data.url)) {
      setUrlError({
        id: params.data.id,
        url: en_translation.quick_link_url_warning,
      });
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: "url",
      });
      return;
    } 
    if(!isValidData(params))
    {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: "title",
      });
      return;
    }
    validateUrl(params.data.url).then((data) => {
      if (data?.category?.toLowerCase() == "green") {
        setValidatingUrl(false);
        setUrlCategory("");
          handleCellValueChanged(params);
          setIsEditing(null);
          setIsNewLink(false);
      } else {
        setValidatingUrl(false);
        setIsModalOpen(true);
        setUrlCategory(data?.category);
        setCurrentParams(params);
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: "title",
        });
      }
    });
  };

  const onSave = (params: any) => {
    onRowEditingStopping(params);
   
  };
  const handleModalConfirm = () => {
    setIsModalOpen(false);
    isValidData(currentParams) && handleCellValueChanged(currentParams);
    setIsEditing(null);
    setIsNewLink(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    listRef.current?.api.setFocusedCell(currentParams.node.rowIndex, "url");
    listRef.current?.api.startEditingCell({
      rowIndex: currentParams.node.rowIndex,
      colKey: "url",
    });
  };
  const actionCellRenderer = (props: any) => {
    const { data } = props;
    return (
      <>
        <div>
          {isAdmin && (
            <>
                <AppIcon
                  icon={props.data.id === isEditing ? floppyDisk : editPen}
                  className={props.data.id === isEditing ?"save-icon" :"edit-icon"}
                  name={props.data.id === isEditing ? "save":"edit"}
                  title={props.data.id === isEditing ?"Save":"Edit" }
                  onClick={() => props.data.id === isEditing ? onSave(props): onCellClicked(props)}
                />
              <AppIcon
                icon={trash}
                className="fa-thin delete-icon"
                name="delete"
                title="Delete"
                onClick={() => handleDelete(data)}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const onCellClicked = (params: any) => {
    setIsEditing(params.data.id);
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: "title",
    });
  };

  const handleToggleClick = (params: any, checked: boolean) => {
    const updatedLink = params.data;
    updatedLink.visibility = checked;
    params.setValue(checked);
    params.api.applyTransaction({ update: [updatedLink] });
    onCellClicked(params);
  };
  const toggleRender = (params: any) => {
    return (
      isAdmin && (
        <AppToggleSwitch
          newDesign={true}
          id="visibility"
          disabled={params.data.id === isEditing ? false : true}
          checked={params.value}
          onChange={(e: any) => handleToggleClick(params, e.target.checked)}
        />
      )
    );
  };

  const linkRenderer = (params: any) => {
    const url = params.data.url;
    const isEYDomain = validateInternalDomain(url);
    return (
      <div className="link-container">
      <a href={url} target="_blank" rel="noreferrer">
        {url}
      </a>
      {isEYDomain && <img className="link-logo-url" src={eyLogoWithCE} alt="EY" title="This is an internal Url" />}
    </div>
    );
  };
  const validateUrl = (url: string): Promise<boolean> => {
    setValidatingUrl(true);
    return axios
      .post(`${API_URL}/api/v1/projects/quick-links/validate-url`, { url })
      .then((response) => response.data)
      .catch((error) => {
        return false;
      });
  };
 
  const columns = [
    {
      headerName: "Link title (Max 50 chars)",
      field: "title",
      flex: 6,
      suppressSizeToFit: true,
      editable: isAdmin,
      cellEditor: "agTextCellEditor",
      cellEditorParams: { maxLength: 50 },
      cellClass: (params) => {
        return validateFormInputForSpecialChar(params.value)
          ? "ag-cell-value-invalid"
          : null;
      },
    },
    {
      headerName: "URL",
      field: "url",
      flex: 14,
      suppressSizeToFit: true,
      editable: isAdmin,
      cellEditor: "agTextCellEditor",
      tooltipValueGetter:(params)=>params.value,
      cellRenderer: linkRenderer,
      cellClass: (params) => {
        return validateProfessionalUrl(params.value)
          ? null
          : "ag-cell-value-invalid";
      },
    },
    isAdmin
      ? {
          headerName: "External user visibility",
          field: "visibility",
          flex: 4,
          suppressSizeToFit: true,
          editable: false,
          cellRenderer: toggleRender,
        }
      : null,
    isAdmin
      ? {
          headerName: "",
          field: "actions",
          flex: 2,
          suppressSizeToFit: true,
          editable: false,
          cellRenderer: actionCellRenderer,
        }
      : null,
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    editable: false,
    isResizable: true,
  };

  const listColumns = columns.filter((col) => col != null);
  const data = useMemo(() => {
    if (!Array.isArray(links)) return [];
  
    const compareTitles = (a, b) => {
      const isANumber = !isNaN(a.title);
      const isBNumber = !isNaN(b.title);
  
      if (a.title === "") return -1;
      if (b.title === "") return 1;
      if (isANumber && !isBNumber) return -1;
      if (!isANumber && isBNumber) return 1;
      if (isANumber && isBNumber) return a.title - b.title;
  
      return a.title.localeCompare(b.title);
    };
  
    if (isInternalUser) {
      return links.sort(compareTitles);
    } else {
      return links
        .filter((x) => x.visibility === true)
        .sort(compareTitles);
    }
  }, [links, isInternalUser]);

  const listRef = useRef<{ api: GridApi }>();
  const containerRef = useRef<HTMLDivElement>(null);
  const onRowEditingStop = (params: any) => {
    if (isEmptyRow(params)) {
      return;
    }
  };
  return (
    <section className="quick-links">
      <span>{"Quick Links"}</span>
      {isModalOpen && urlCategory && urlCategory?.toLocaleLowerCase() != "green" && (
          <AppModal
              showModal={isModalOpen}
              onModalClose={handleModalCancel}
              onConfirm={
                urlCategory?.toLocaleLowerCase() != "red" && handleModalConfirm
              }
              newDesign={true}
              size={"md"}
              cancelBtnText={urlCategory?.toLocaleLowerCase() == "red" ? en_translation.Close :en_translation.admin_portal_project_action_no}
              confirmBtnText={en_translation.yes_proceed}
              title={urlCategory?.toLocaleLowerCase() == "red" ? en_translation.non_professional_url_restricted: en_translation.non_professional_url_title}
              fullWidth={true}
            >
            <div className="modal-content">
              <AppAlert
                severity={
                  urlCategory?.toLocaleLowerCase() == "red"
                    ? "error"
                    : "warning"
                }
                className="error-alert-custom"
              >
                {urlCategory?.toLocaleLowerCase() == "red"
                  ? en_translation.url_restrictions_error
                  : en_translation.url_restrictions_warning}
                  <br/>
                  {urlCategory?.toLocaleLowerCase() == "amber" && en_translation.non_professional_url_confirmation}
              </AppAlert>
            </div>
          </AppModal>
        )}
      {isAdmin && data.length > 0 && (
        <div className="add-new-link-button">
          <AppButton
            id="add-link-button"
            onClick={handleAddLink}
            size="medium"
            variant="secondary"
            className="containedSecondary"
            disabled={isEditing !== null || isNewLink}
          >
            <AppIcon icon={add} className="add-icon" />
            {en_translation.add_new_link}
          </AppButton>
        </div>
      )}
      {!isLoading && data.length < 1 && (
        <div className="add-quick-links">
          <div className="no-link-found">
            <span className="no-links">{en_translation.no_link_found}</span>
          </div>
          <div>
            <span className="start-addition-links">
              {en_translation.start_add_quick_link}
            </span>
          </div>
          {isAdmin && (
            <div>
              <AppButton
                id="add-link-button"
                onClick={handleAddLink}
                size="medium"
                className="containedSecondary"
              >
                <AppIcon icon={add} className="add-icon" />
                {en_translation.add_quick_link}
              </AppButton>
            </div>
          )}
        </div>
      )}
      {data.length > 0 &&
        (isValidatingUrl ? (
          <Loader isLoading={true}></Loader>
        ) : (
          <div ref={containerRef} className="quick-links-list">
            {(Array.isArray(titleErrorList) && titleErrorList?.length > 0) ||
            (Array.isArray(urlErrorList) && urlErrorList.length > 0) ? (
              <>
                <div>
                  {[
                    ...new Set(
                      titleErrorList
                        .filter((x) => x.title)
                        .map((error) => error.title)
                    ),
                  ].map((title, index) => (
                    <AppAlert
                      key={index}
                      severity="error"
                      className="error-alert-custom"
                    >
                      {"Error: "}
                      {title}
                    </AppAlert>
                  ))}
                </div>
                <div>
                  {[
                    ...new Set(
                      urlErrorList
                        .filter((x) => x.url)
                        .map((error) => error.url)
                    ),
                  ].map((url, index) => (
                    <AppAlert
                      key={index}
                      severity="error"
                      className="error-alert-custom"
                    >
                      {"Warning: "}
                      {url}
                    </AppAlert>
                  ))}
                </div>
              </>
            ) : (
              <div className="header-padding"></div>
            )}
            <List
              rowHeight={30}
              withStickyScroll
              rowSelection="single"
              firstColumnBorderRight={true}
              columnConfig={listColumns}
              defaultColDef={defaultColDef}
              deltaRowDataMode={true}
              rowData={data}
              paginate={false}
              onGridReady={onGridReady}
              setDomLayout="normal"
              gridOptions={{
                headerHeight: 30,
                defaultColDef: defaultColDef,
                onRowEditingStopped: onRowEditingStop,
                suppressClickEdit: true,
                sizeColumnsToFit: true,
                editType: "fullRow",
                autoSizeStrategy: {
                  type: "fitGridWidth",
                  defaultMinWidth: 100,
                },
              }}
            />
          </div>
        ))}
    </section>
  );
};

export default QuickLinks;
